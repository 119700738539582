import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

const CustDialog = ({
  isOpen,
  handleClose,
  title,
  subTitle,
  cancelBtnTxt,
  cancelBtnFn,
  submitBtnTxt,
  submitBtnFn,
}) => {

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
    >
      <DialogTitle className="font-weight-bold">{title}</DialogTitle>

      {subTitle && (
        <DialogContent>
          <DialogContentText>
            {subTitle}
          </DialogContentText>
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={cancelBtnFn}>{cancelBtnTxt}</Button>
        <Button onClick={submitBtnFn} variant="contained" color="primary" autoFocus>{submitBtnTxt}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CustDialog;
