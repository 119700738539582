import { get, getForReport, post } from "../lib/request";


export const fetchDriverDetails = (
  country_id,
  skip,
  limit
) => {
  let URL = `/driver?countries=${country_id}&skip=${skip || 0}&limit=${limit || 10}`;
  return getForReport(URL);
};


// ----------------- Independent Driver -------------------
export const fetchIndependentDriver = (
  start_time,
  end_time,
  city_id,
) => {
  let URL = `/dashboard/independent?start_time=${start_time}&end_time=${end_time}&city_id=${city_id}`;
  return getForReport(URL);
};

// ----------------- Fleet Driver -------------------
export const fetchFleetDriver = (
  start_time,
  end_time,
  city_id,

) => {
  let URL = `/dashboard/fleet?start_time=${start_time}&end_time=${end_time}&city_id=${city_id}`;
  return getForReport(URL);
};

// ----------------- Store Driver -------------------
export const fetchStoreDriver = (
  start_time,
  end_time,
  city_id,

) => {
  let URL = `/dashboard/store?start_time=${start_time}&end_time=${end_time}&city_id=${city_id}`;
  return getForReport(URL);
};


// ----------------- Generated Report -------------------
export const fetchGeneratedReport = (
  city_id,
  start_time,
  end_time,
  skip,
  limit,
  service

) => {
  if (start_time && end_time) {
    let URL = `/report?start_time=${start_time}&end_time=${end_time}&city_id=${city_id}&service=${service}&skip=${skip || 0}&limit=${limit || 10}`;
    return getForReport(URL);
  }
  else {
    let URL = `/report?city_id=${city_id}&service=${service}&skip=${skip || 0}&limit=${limit || 10}`;
    return getForReport(URL);
  }

};


// 3rd Table
// ----------------- Total Trip -------------------
export const fetchTotalTrip = (
  start_time,
  end_time,
  cityId,
  service,
  skip,
  limit,
  exportData,
) => {
  let URL = `/logs/total_trip?start_time=${start_time}&end_time=${end_time}&service=${service}&city_id=${cityId}&skip=${skip || 0}&limit=${limit || 10}&export=${exportData}`;
  return getForReport(URL);
};

// ----------------- Total Cash Trip -------------------
export const fetchTotalCashTrip = (
  start_time,
  end_time,
  cityId,
  service,
  skip,
  limit,
  exportData,

) => {
  let URL = `/logs/cash_trip?start_time=${start_time}&end_time=${end_time}&service=${service}&city_id=${cityId}&skip=${skip || 0}&limit=${limit || 10}&export=${exportData}`;
  return getForReport(URL);
};

// ----------------- Total Card Trip -------------------
export const fetchTotalCardTrip = (
  start_time,
  end_time,
  cityId,
  service,
  skip,
  limit,
  exportData,
) => {
  let URL = `/logs/card_trip?start_time=${start_time}&end_time=${end_time}&service=${service}&city_id=${cityId}&skip=${skip || 0}&limit=${limit || 10}&export=${exportData}`;
  return getForReport(URL);
};

// ----------------- Total Drivers In Cycle -------------------
export const fetchTotalDriversInCycle = (
  start_time,
  end_time,
  cityId,
  service,
  skip,
  limit,
  exportData,
) => {
  let URL = service === 1 ? `/logs/driver_cycle?start_time=${start_time}&end_time=${end_time}&service=${service}&city_id=${cityId}&skip=${skip || 0}&limit=${limit || 10}&export=${exportData}`
    : `/logs/store_cycle?start_time=${start_time}&end_time=${end_time}&service=${service}&skip=${skip || 0}&limit=${limit || 10}&export=${exportData}&city_id=${cityId}`
  return getForReport(URL);
};

// ----------------- Drivers To Collect From --&--- Drivers To pay -------------------
export const fetchDriversToCollectFrom = (
  start_time,
  end_time,
  cityId,
  service,
  method,
  skip,
  limit,
  exportData,
) => {
  let URL = service === 1 ? `/driver/payment/independent?start_time=${start_time}&end_time=${end_time}&service=${service}&method=${method}&city_id=${cityId}&skip=${skip || 0}&limit=${limit || 10}&export=${exportData}`
    : `/store/payment?start_time=${start_time}&end_time=${end_time}&service=${service}&method=${method}&city_id=${cityId}&skip=${skip || 0}&limit=${limit || 10}&export=${exportData}`;
  return getForReport(URL);
};


//post api for getting the export data
export const GenerateReportData = (body) => {
  return post(`/report`, { body });
};


// ----------------- Driver Booking -------------------
export const fetchDriverBooking = (
  start_time,
  end_time,
  cityId,
  service,
  skip,
  limit,
  driver_id,
  exportData,
) => {
  let URL = `/driver/booking?start_time=${start_time}&end_time=${end_time}&service=${service}&city_id=${cityId}&skip=${skip || 0}&limit=${limit || 10}&driver_id=${driver_id}&export=${exportData}`;
  return getForReport(URL);
};



// ----------------- Driver Order -------------------
export const fetchDriverOrder = (
  start_time,
  end_time,
  cityId,
  service,
  skip,
  limit,
  driver_id,
  exportData,
) => {
  let URL = `/driver/order?start_time=${start_time}&end_time=${end_time}&service=${service}&city_id=${cityId}&skip=${skip || 0}&limit=${limit || 10}&driver_id=${driver_id}&export=${exportData}`;
  return getForReport(URL);
};



//post api for getting the export data
export const payment = (body) => {
  console.log("request----- body",body)
  return post(`/report/payment`, { body });
};
