// Dependency Imports
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// Custom Imports
import "../../static/scss/App.scss";
import Header from "../../components/header/header";
//icons
import PollIcon from "@material-ui/icons/Poll";
// import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
//helper function
import {
  Route,
  withRouter,
  // BrowserRouter as Router,
  // Redirect,
} from "react-router-dom";



// import Demo from "./Demo/index";
import DriverPayout from "./DriverPayout";

class LandingIndex extends React.Component {
  state = {
    cartLogs: " ",
    ProductRecentView: " ",
    FavProduct: " ",
    sidePanel: [

      // {
      //   name: "Demo Overview",
      //   url: `${this.props.match.url}/demo-overview`,
      //   icon: PollIcon,
      //   component: Demo,
      // },


      {
        name: "Driver Payout Reports",
        url: `${this.props.match.url}/driver-payout-reports`,
        icon: PollIcon,
        component: DriverPayout,
      },

      // {
      //   name: "Driver Reports",
      //   url: `${this.props.match.url}/driver--reports`,
      //   icon: PollIcon,
      //   component: ProductSearchedLogs,
      // },

    ],
  };

  render() {
    if (this.props.location.pathname === "/") {
      this.props.history.push("/dashbord/driver-payout-reports");
    }
    return (
      <div className="dash-board">
        <Header
          title="shoppd - Dashboard"
          data={this.state.sidePanel}
          url={`${this.props.match.url}/driver-payout-reports`}
        ></Header>

        <div
          className="dashbord-body"
          // style={{ height: "100vh", overflow: "auto" }}
        >
          {/* <SidePanel data={this.state.sidePanel}></SidePanel> */}

          <div className="">
            {this.state.sidePanel.map((items, index) => {
              return (
                <Route
                  key={index}
                  path={items.url}
                  name={items.name}
                  exact
                  component={items.component}
                ></Route>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

// Create validations for Props, required or type etc.
LandingIndex.propTypes = {
  dispatch: PropTypes.any.isRequired,
  lang: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => {
  return {
    index: state.index,
  };
};

export default connect(mapStateToProps, null)(withRouter(LandingIndex));
