//library import
import moment from "moment";
import { connect } from "react-redux";
import React, { Component } from "react";
import { setIndex } from "../../../redux/actions/auth";
import jstz from "jstz";
import ls from "local-storage";

// import IconLabelButtons from './payCollectButton' 
import { first_response } from "./product";

// Page Header part 
import Headers from "./header/index.js";

// Alter (snackbar or toster) 
import Snackbar from "../../../components/snackbar/snackbar";

import ReactTable from "../../../components/reactTable/reactTable";

// spinner loader
import ClipLoader from "react-spinners/ClipLoader";

// download icon using for download reports 
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { Button } from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';
import LoopIcon from '@material-ui/icons/Loop';
// data export 
import Modal from "../../../components/modal/modal";
import Pbutton from "../../../components/button/Button";
import { CSVLink } from "react-csv";

// css file
import "./index.css";
import "./Overview.scss";
import "../Common.scss";
import "../global-font-css.css";
import "../../../static/scss/globalComponent.scss";

// Dropdown filter
import { getCountries } from "../../../services/locationFilter";
import { statusFilter } from "../../../fixtures/dateFilter/statusFilter";

import _ from "lodash";

// API Imports
import {
  fetchTotalTrip, fetchDriverDetails,
  fetchIndependentDriver, fetchStoreDriver,
  fetchFleetDriver, fetchTotalCashTrip,
  fetchTotalCardTrip, fetchTotalDriversInCycle,
  fetchDriversToCollectFrom, fetchGeneratedReport, GenerateReportData,
  fetchDriverOrder, fetchDriverBooking, payment
} from "../../../services/driverPayout";

// helper 
import { getCurrencySymbol, replaceBlankWithDash, serviceValue } from "../../../lib/helper";

import PaginationJs from "../../../components/paginationJs/paginationJs";

// for page navigator
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs"

// Default API params
import { DRIVER_CODES, METHOD, DEFAULT_COUNTRY, PROJECT_TYPE_SUPPORT, USER_TYPE_SUPPORT, TXN_TYPE_SUPPORT } from "../../../lib/config";
import CustDialog from "../../../components/Dialog";

/**
 * @Author Jai
 * @Date 29 May, 2021
 * @Description Created a Demo dashboard with location filter, status, and date range
 */

class DriverPayoutOverview extends Component {
  myRef = React.createRef();
  state = {
    dataLoaded: false,
    isOpenSnackbar: false,
    snackbarMessage: "",

    storeIdValue: "0",
    storeData: statusFilter,

    isDisplayChart: false,
    noDataText: "",

    country: "",

    countryId: window && window._env_ && window._env_.COUNTRY ? window._env_.COUNTRY : DEFAULT_COUNTRY.COUNTRY,
    projectType: window && window._env_ && window._env_.PROJECT_TYPE_SUPPORT ? window._env_.PROJECT_TYPE_SUPPORT : PROJECT_TYPE_SUPPORT.ALL,
    // projectType :1,
    countries: [],
    cityId: "",

    exportData: 0,
    startDate: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment().format("YYYY-MM-DD HH:mm:ss"),

    sortBy: 1,

    skip: 0,            //starting point
    limit: 10,          //ending point
    page: 0,            //pagination page
    dataToShow: 10,     //20, 40, 60 or 80 data to show
    totalData: Number,  // total no of data


    generatedReportCount: Number,
    driverCycleCount: Number,

    loader: true,       //loader state
    export: true,
    showExportPage: false,

    showFirst: false,
    showSec: false,
    showThird: false,
    showFourth: false,

    logsData: [],           //table data
    secondlogsData: [],     //table data
    thirdlogsData: [],      //table data
    driversInCycleData: [],

    logsColumn: [],

    // service type sort
    sortByService: [
      { label: "Independent", value: 1 },
      { label: "Fleet", value: 2 },
      { label: "Store", value: 3 },
    ],
    selectedSortByService: { label: "Independent", value: 1 },
    isOpen: false,
    payNowCollectionFromState: null,
    payNowLoader: false
  };

  componentDidMount = () => {
    let width = window.innerWidth;
    this.setState({ width });
    window.addEventListener("resize", this.heightHandler);
    const { startDate, endDate } = this.state;
    localStorage.setItem("startDate", startDate);
    localStorage.setItem("endDate", endDate);
    this.handleFilterData("", "initialCall");

    this.getData(
      this.state.countryId,
      this.state.skip,
      this.state.limit,
    );

  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.countryId !== prevState.countryId
    ) {

      this.setState({
        showFirst: false,
        loader: true,
        skip: 0,
        limit: 10
      })

      this.getData(
        this.state.countryId,
        this.state.skip,
        this.state.limit,
      );
    }
    if (
      this.state.page !== prevState.page ||
      this.state.dataToShow !== prevState.dataToShow
    ) {
      if (this.state.tableHeader === "Generated Report" && this.state.callGeneratedReport) {
        this.generateReport()
      }
      else if (this.state.tableHeader === "Generated Report") {
        this.drivePayoutCellHandler(this.state.drivePayoutEvent)
      }
      else if (this.state.driversCycleHeader === "Delivery Count" || this.state.driversCycleHeader === "Ride Count") {
        this.subDriversCycleHandler(this.state.driversCycleEvent)
      }
      else {
        this.secPayoutCellHandler(this.state.secPayoutEvent)
      }
    }
  }

  //set data to show and respectively set SKIP and LIMIT
  dataToShowHandler = (newVal) => {
    let stateCopy = { ...this.state };

    stateCopy.page = 1;
    stateCopy.skip = 0;
    stateCopy.limit = newVal;
    stateCopy.dataToShow = newVal;
    stateCopy.loader = true;
    this.setState(stateCopy);
  };

  // change pagination page  respectively set SKIP and LIMIT
  changeActivePage = (page) => {
    let state = { ...this.state };

    state.skip = page - 1;
    state.limit = state.dataToShow;
    state.page = page;
    state.loader = true;
    this.setState(state);

  };


  drivePayoutCellHandler = (e) => {
    this.setState({
      showFirst: false,
      showSec: true,
      skip: 0,
      limit: 10,
      // page: 0,            //pagination page
      // dataToShow: 10,       
    })
    const tableHeader = e.column.Header
    this.setState({
      drivePayoutEvent: e,
      tableHeader,
      cityId: e.original.cityId,
      cityName: e.original.cityName,
    })
    const { startDate, endDate, cityId, skip, limit } = this.state
    if (tableHeader == "Generated Report") {
      fetchGeneratedReport(
        e.original.cityId,
        "", "", // start_time and end time sending null in the begining
        skip,
        limit,
        DRIVER_CODES.INDEPENDENT_DRIVER
      )
        .then(data => {
          this.setState({
            secondlogsData: data.data.data,
            generatedReportCount: data.data.count,
            dataLoaded: true,
            loader: false,
            showSec: true,
          })
        })
        .catch(error => {
          console.log("error==>", error.response.statusText)
          if (!this.state.startDate) {
            this.setState({
              isOpenSnackbar: true,
              snackbarMessage: "Please select a date range",
            });
          } else {
            let message =
              error &&
                error.response &&
                error.response.statusText
                ? error.response.statusText
                : "Data Not Found!";
            this.setState({
              dataLoaded: false,
              loader: false,
              isOpenSnackbar: true,
              snackbarMessage: message,
            });
          }
          setTimeout(() => {
            this.setState({
              isOpenSnackbar: false,
            });
          }, 1000);


        })
    }
  }

  // First Table
  driverPayoutReportColumns = [
    {
      Header: 'City',
      accessor: data => replaceBlankWithDash(data.cityName),
      id: "cityName",
      minWidth: 60,

    },
    {
      Header: "Independent Drivers",
      accessor: data => replaceBlankWithDash(data.independent),
      id: "independent",
      Cell: e => {
        return <span style={{ color: "blue", cursor: "pointer" }} onClick={this.drivePayoutCellHandler.bind(this, e)} > {e.value} </span>
      }
    },

    {
      Header: "Fleet Operators",
      accessor: data => replaceBlankWithDash(data.fleet),
      id: "fleet",
      // minWidth: 160,
      Cell: e => {
        return <span style={{ color: "blue", cursor: "pointer" }} onClick={this.drivePayoutCellHandler.bind(this, e)} > {e.value} </span>
      }

    },

    {
      Header: "Stores",
      accessor: data => replaceBlankWithDash(data.store),
      id: "store",
      Cell: e => {
        return <span style={{ color: "blue", cursor: "pointer" }} onClick={this.drivePayoutCellHandler.bind(this, e)} > {e.value} </span>
      }


    },

    {
      Header: "Generated Report",
      accessor: data => replaceBlankWithDash(data.generated_report),
      id: "generated_report",
      Cell: e => {
        return <Button
          onClick={this.drivePayoutCellHandler.bind(this, e)} > <CloudDownloadIcon></CloudDownloadIcon> </Button>
      }


    },
  ];

  // Generated report functionalities
  generatedReportColumns = [

    {
      Header: "Sr No",
      accessor: data => replaceBlankWithDash(data.city_name),
      id: "city_name",

      Cell: ({ column, cell, index, row }) => {
        let page = this.state.page < 1 ? 1 : this.state.page
        const serialNumber = Number(`${page > 1 && index == 9 ? page : page - 1}${index === 9 && page > 1 ? 0 : index + 1}`)

        return serialNumber
      },
    },

    {
      Header: "City Name",
      accessor: data => replaceBlankWithDash(data.city_name),
      id: "city_name",
    },

    {
      Header: 'Report Start Date',
      accessor: data => replaceBlankWithDash(data.start_ts),
      id: "start_ts",
      Cell: ({ column, cell, row }) => {
        return moment.unix(row.start_ts).format("DD-MMM-YYYY HH:mm:ss")
      },

    },
    {
      Header: "Report End Date",
      accessor: data => replaceBlankWithDash(data.end_ts),
      id: "end_ts",
      Cell: ({ column, cell, row }) => {
        return moment.unix(row.end_ts).format("DD-MMM-YYYY HH:mm:ss")
      },
    },

    {
      Header: "Generated On",
      accessor: data => replaceBlankWithDash(data.create_ts),
      id: "create_ts",
      Cell: ({ column, cell, row }) => {
        return moment.unix(row.create_ts).format("DD-MMM-YYYY HH:mm:ss")
      },
    },

    {
      Header: "Service Type",
      accessor: data => replaceBlankWithDash(data.service_type_name),
      id: "service_type_name",

    },

    {
      Header: "Report Link",
      accessor: data => replaceBlankWithDash(data.url),
      id: "url",
      Cell: ({ column, cell, row }) => (
        <Button
          style={{ padding: "0px", minWidth: "0px" }}
          href={row.url}
          target="_self"
          download
        >
          <CloudDownloadIcon></CloudDownloadIcon>
        </Button>
      ),

    },

  ]

  handleDialogOpen = (id, val, userTypeSupport, txtTypeSupport) => {
    // -------
    this.setState({
      payNowCollectionFromState: {
        id,
        val,
        userTypeSupport,
        txtTypeSupport
      },
      payNowLoader:true
    })

    this.setState({
      isOpen: true,
    })
  }

  handleDialogClose = () => {
    this.setState({
      isOpen: false,
    })
  }

  openConfirmDialog = () => {
    console.log("====================================================================", this.state.isOpen)
    this.setState({
      loader:true
    })
    this.PayNowAndCollectFrom(
      this.state.payNowCollectionFromState.id,
      this.state.payNowCollectionFromState.val,
      this.state.payNowCollectionFromState.userTypeSupport,
      this.state.payNowCollectionFromState.txtTypeSupport,
    )
  }

  secPayoutCellHandler = (e) => {
    this.setState({
      showFirst: false,
      showSec: false,
      showFourth: false,
      driversCycleHeader: "",
      loader: true,
      skip: 0,
      limit: 10,
    })

    if (this.state.skip === 0) {
      this.setState({
        page: 1,
      })
    }

    if (this.state.showSec) {
      this.setState({
        dataToShow: 10
      })

    }
    const { startDate, endDate, exportData, cityId, tableHeader, skip, limit } = this.state

    const logsHeader = e.original.key
    this.setState({
      secPayoutEvent: e,
      logsHeader,
    })
    let service = serviceValue(tableHeader)
    // console.log('service', service)
    if (logsHeader === "Total Trips" || logsHeader === "Total Orders") {
      fetchTotalTrip(
        moment(startDate).unix(),
        moment(endDate).unix(),
        cityId,
        service,
        skip,
        limit,
        exportData
      )
        .then(data => {
          const logsColumnKey = Object.keys(data.data.data[0])
          const logsColumn = logsColumnKey.map((key) => {
            return {
              Header: key,
              accessor: data => replaceBlankWithDash(data[key]),
              id: key,
              minWidth: key === "From" || key === "To" ? 250 : 160,
            }
          })
          this.setState({
            showThird: true,
            logsColumn,
            thirdlogsData: data.data.data,
            totalData: data.data.count,
            dataLoaded: true,
            loader: false,
          })
        })
        .catch(error => {
          console.log("error", error.response)
          if (!this.state.startDate) {
            this.setState({
              isOpenSnackbar: true,
              snackbarMessage: "Please select a date range",
            });
          } else {
            let message =
              error &&
                error.response &&
                error.response.statusText
                ? error.response.statusText
                : "Data Not Found!";
            this.setState({
              dataLoaded: false,
              loader: false,
              isOpenSnackbar: true,
              snackbarMessage: message,
            });
          }
          setTimeout(() => {
            this.setState({
              isOpenSnackbar: false,
            });
          }, 1000);
        })
    }
    else if (logsHeader === "Total Cash Trips" || logsHeader === "Total Cash Orders") {
      fetchTotalCashTrip(
        moment(startDate).unix(),
        moment(endDate).unix(),
        cityId,
        service,
        skip,
        limit,
        exportData
      )
        .then(data => {
          const logsColumnKey = Object.keys(data.data.data[0])
          const logsColumn = logsColumnKey.map((key) => {
            return {
              Header: key,
              accessor: data => replaceBlankWithDash(data[key]),
              id: key,
              minWidth: key === "From" || key === "To" ? 250 : 160,
            }
          })
          this.setState({
            showThird: true,
            logsColumn,
            thirdlogsData: data.data.data,
            totalData: data.data.count,
            dataLoaded: true,
            loader: false,
          })
        })
        .catch(error => {
          console.log("error", error.response.data)
          if (!this.state.startDate) {
            this.setState({
              isOpenSnackbar: true,
              snackbarMessage: "Please select a date range",
            });
          } else {
            let message =
              error &&
                error.response &&
                error.response.statusText
                ? error.response.statusText
                : "Data Not Found!";
            this.setState({
              dataLoaded: false,
              loader: false,
              isOpenSnackbar: true,
              snackbarMessage: message,
            });
          }
          setTimeout(() => {
            this.setState({
              isOpenSnackbar: false,
            });
          }, 1000);
        })
    }
    else if (logsHeader === "Total Online payment Trips" || logsHeader === "Total Online payment Orders") {
      fetchTotalCardTrip(
        moment(startDate).unix(),
        moment(endDate).unix(),
        cityId,
        service,
        skip,
        limit,
        exportData,
      )
        .then(data => {
          const logsColumnKey = Object.keys(data.data.data[0])
          const logsColumn = logsColumnKey.map((key) => {
            return {
              Header: key,
              accessor: data => replaceBlankWithDash(data[key]),
              id: key,
              minWidth: key === "From" || key === "To" ? 250 : 160,
            }
          })
          this.setState({
            showThird: true,
            logsColumn,
            thirdlogsData: data.data.data,
            totalData: data.data.count,
            dataLoaded: true,
            loader: false,
          })
        })
        .catch(error => {
          console.log("error", error)
          if (!this.state.startDate) {
            this.setState({
              isOpenSnackbar: true,
              snackbarMessage: "Please select a date range",
            });
          } else {
            let message =
              error &&
                error.response &&
                error.response.statusText
                ? error.response.statusText
                : "Data Not Found!";
            this.setState({
              dataLoaded: false,
              loader: false,
              isOpenSnackbar: true,
              snackbarMessage: message,
            });
          }
          setTimeout(() => {
            this.setState({
              isOpenSnackbar: false,
            });
          }, 1000);
        })
    }
    else if (logsHeader === "Total Drivers In Cycle" || logsHeader === "Total Stores In Cycle") {
      fetchTotalDriversInCycle(
        moment(startDate).unix(),
        moment(endDate).unix(),
        cityId,
        service,
        skip,
        limit,
        exportData
      )
        .then(data => {
          const logsColumnKey = Object.keys(data.data.data[0])
          logsColumnKey.splice(1, 1)
          const logsColumn = logsColumnKey.map((key) => {
            return {
              Header: key,
              accessor: data => replaceBlankWithDash(data[key]),
              id: key,
              minWidth: 160,
              Cell: e => {
                let colorKey = e.column.Header === "Ride Count" || e.column.Header === "Delivery Count" ? "blue" : ""
                return <span style={{
                  color: colorKey,
                  cursor: colorKey === "blue" ? "pointer" : ""
                }}
                  onClick={colorKey === "blue" && this.subDriversCycleHandler.bind(this, e)} > {e.value} </span>
              }
            }
          })
          this.setState({
            showThird: true,
            logsColumn,
            thirdlogsData: data.data.data,
            totalData: data.data.count,
            dataLoaded: true,
            loader: false,
          })
        })
        .catch(error => {
          console.log("error", error)
          if (!this.state.startDate) {
            this.setState({
              isOpenSnackbar: true,
              snackbarMessage: "Please select a date range",
            });
          } else {
            let message =
              error &&
                error.response &&
                error.response.statusText
                ? error.response.statusText
                : "Data Not Found!";
            this.setState({
              dataLoaded: false,
              loader: false,
              isOpenSnackbar: true,
              snackbarMessage: message,
            });
          }
          setTimeout(() => {
            this.setState({
              isOpenSnackbar: false,
            });
          }, 1000);
        })
    }
    else if (logsHeader === "Drivers To Collect From" || logsHeader === "Stores To Collect From") {
      fetchDriversToCollectFrom(
        moment(startDate).unix(),
        moment(endDate).unix(),
        cityId,
        service,
        METHOD['Drivers To Collect From'],
        skip,
        limit,
        exportData,
      )
        .then(data => {
          const logsColumnKey = Object.keys(data.data.data[0]).filter(item => item !== "_id")
          const logsColumn = logsColumnKey.map((key) => {
            return {
              Header: key,
              accessor: data => replaceBlankWithDash(data[key]),
              id: key,
              minWidth: key === "Amount To Collect" ? 200 : 160,
              Cell: e => {
                let userTypeSupport = logsHeader === "Drivers To Collect From" ? USER_TYPE_SUPPORT.DRIVER : logsHeader === "Stores To Collect From" ? USER_TYPE_SUPPORT.STORE : ""
                let collectId = e.original._id
                console.log("collectId",collectId, "e.value", e.value, "TXN_TYPE_SUPPORT.COLLECT",TXN_TYPE_SUPPORT.COLLECT, )
                return <div>
                  <span
                    className={`${key === "Amount To Collect" ? 'd-flex justify-content-between' : ""}`}>
                    {e.value}
                    {key === "Amount To Collect" ?
                    // <Button className="text-right" variant="contained" color="primary" onClick={this.PayNowAndCollectFrom.bind(this, e, userTypeSupport, TXN_TYPE_SUPPORT.COLLECT)} endIcon={<SendIcon />}>
                      <Button className="text-right" variant="contained" color="primary" onClick={this.PayNowAndCollectFrom.bind(this, collectId, e.value.split(" ")[1], userTypeSupport, TXN_TYPE_SUPPORT.COLLECT)} endIcon={ this.state.loader? <LoopIcon/> : <SendIcon />}>
                        {
                          this.state.loader ? "Please wait.." : "Collect"
                        }
                        {/* Collect  */}
                      </Button>
                      : ""
                    }
                  </span>
                </div>
              }
            }
          })
          this.setState({
            showThird: true,
            logsColumn,
            thirdlogsData: data.data.data,
            totalData: data.data.count,
            dataLoaded: true,
            loader: false,
          })
        })
        .catch(error => {
          console.log("error", error.response)
          if (!this.state.startDate) {
            this.setState({
              isOpenSnackbar: true,
              snackbarMessage: "Please select a date range",
            });
          } else {
            let message =
              error &&
                error.response &&
                error.response.statusText
                ? error.response.statusText
                : "Data Not Found!";
            this.setState({
              dataLoaded: false,
              loader: false,
              isOpenSnackbar: true,
              snackbarMessage: message,
            });
          }
          setTimeout(() => {
            this.setState({
              isOpenSnackbar: false,
            });
          }, 1000);
        })
    }
    else if (logsHeader === "Drivers To pay" || logsHeader === "Stores To pay") {
      fetchDriversToCollectFrom(
        moment(startDate).unix(),
        moment(endDate).unix(),
        cityId,
        service,
        METHOD['Drivers To pay'],
        skip,
        limit,
        exportData
      )
        .then(data => {
          const logsColumnKey = Object.keys(data.data.data[0]).filter(item => item !== "_id")

          const logsColumn = logsColumnKey.map((key) => {
            return {
              Header: key,
              accessor: data => replaceBlankWithDash(data[key]),
              id: key,
              minWidth: key === "Amount To Be Paid" ? 200 : 160,
              Cell: e => {
                let userTypeSupport = logsHeader === "Drivers To pay" ? USER_TYPE_SUPPORT.DRIVER : logsHeader === "Stores To pay" ? USER_TYPE_SUPPORT.STORE : ""
                return <div>
                  <span
                    className={`${key === "Amount To Be Paid" ? 'd-flex justify-content-between' : ""}`}>
                    {e.value}
                    
                    {key === "Amount To Be Paid" ?
                      <Button
                        className="text-right"
                        variant="contained"
                        color="primary"
                        endIcon={this.state.payNowLoader && e.original._id == this.state.payNowCollectionFromState.id ? <LoopIcon/> : <SendIcon />}
                        onClick={() => this.handleDialogOpen(
                          e.original._id,
                          e.value.split(" ")[1],
                          userTypeSupport,
                          TXN_TYPE_SUPPORT.PAY
                          )
                        }>
                          {this.state.payNowLoader && e.original._id == this.state.payNowCollectionFromState.id ? "Processing" : "Pay Now"}
                        {/* Pay Now */}
                      </Button>
                      : ""
                    }
                  </span>
                </div>
              }
            }
          })
          this.setState({
            showThird: true,
            logsColumn,
            thirdlogsData: data.data.data,
            totalData: data.data.count,
            dataLoaded: true,
            loader: false,
          })
        })
        .catch(error => {
          console.log("error", error.response)
          if (!this.state.startDate) {
            this.setState({
              isOpenSnackbar: true,
              snackbarMessage: "Please select a date range",
            });
          } else {
            let message =
              error &&
                error.response &&
                error.response.statusText
                ? error.response.statusText
                : "Data Not Found!";
            this.setState({
              dataLoaded: false,
              loader: false,
              isOpenSnackbar: true,
              snackbarMessage: message,
            });
          }
          setTimeout(() => {
            this.setState({
              isOpenSnackbar: false,
            });
          }, 1000);
        })
    }
    else {
      this.setState({
        dataLoaded: false,
        loader: false,
        isOpenSnackbar: true,
        snackbarMessage: "Data Not Found"
      })
    }
    setTimeout(() => {
      this.setState({
        isOpenSnackbar: false,
      });
    }, 1000);

  }

  //for getting the export report besed on selected date.
  PayNowAndCollectFrom = async (id, val, user_type_support, txtTypeSupport) => {
    console.log(id, val, user_type_support, txtTypeSupport)
    this.setState({
      loader: true,
    })
    const currency = ls.get("currency") || "INR";
    // const currency = "INR";
    const currency_symbol = getCurrencySymbol(currency);

    const dataToSend = {
      currency: currency,
      currency_symbol: currency_symbol,
      _id: id,
      txn_type: txtTypeSupport,
      user_type: user_type_support,
      amount: Number(val)
    };

    //calling the post api
    payment(dataToSend)
      .then((res) => {
        this.setState({
          loader: true,
          payNowLoader: false
        })
        this.secPayoutCellHandler(this.state.secPayoutEvent)

        console.log('paid successful', res)
      })
      .catch((error) => {
        console.log(error, "errrrrrrrrrrrrr", 'response', error.response, 'status',error.response.status);
        let message = error &&
          error.response &&
          error.response.data &&
          error.response.data.message
          ? error.response.data.message
          : "Data Not Found!";        
        this.setState({
          dataLoaded: false,
          loader: false,
          payNowLoader:false,
          isOpenSnackbar: true,
          snackbarMessage: message,
        });
        setTimeout(() => {
          this.setState({
            isOpenSnackbar: false,
          });
        }, 1000);
      });

    setTimeout(() => this.handleDialogClose(), 1000);
  };

  secPayoutReportColumns = [

    {
      Header: "Name",
      accessor: data => replaceBlankWithDash(data.key),
      id: "key",
      minWidth: 60,
    },
    {
      Header: "Value",
      accessor: data => replaceBlankWithDash(data.value),
      id: "value",
      Cell: e => {
        let colorKey = e.row.key === "From" || e.row.key === "To" || e.row.key === "Cash Collected" || e.row.key === "Online payment Collected" || e.row.key === "Wallet payment Collected" ? "" : "blue"
        return <div>
          <span style={{
            color: colorKey,
            cursor: colorKey === "blue" ? "pointer" : ""
          }}
            className="d-flex justify-content-between"
            onClick={colorKey === "blue" && this.secPayoutCellHandler.bind(this, e)}  >
            {e.row.key === "From" || e.row.key === "To"
              ? moment.unix(e.value).format("DD-MMM-YYYY HH:mm:ss")
              : e.value
            }
          </span>
        </div>
      }
    },
  ];

  subDriversCycleHandler = (e) => {
    this.setState({
      showFirst: false,
      showSec: false,
      showThird: false,
      loader: true,
      skip: 0,
      limit: 10,
      // page: 0,            //pagination page
      // dataToShow: 10,       
    })
    const driversCycleHeader = e.column.Header;
    const driverId = e.original.driverId
    const { startDate, endDate, exportData, cityId, tableHeader, skip, limit } = this.state
    this.setState({
      driversCycleEvent: e,
      driversCycleHeader,
    })

    if (this.state.skip === 0) {
      this.setState({
        page: 1
      })
    }

    if (this.state.showThird) {
      this.setState({
        dataToShow: 10
      })
    }

    let service = serviceValue(tableHeader)

    if (driversCycleHeader === "Ride Count") {
      fetchDriverBooking(
        moment(startDate).unix(),
        moment(endDate).unix(),
        cityId,
        service,
        skip,
        limit,
        driverId,
        exportData
      )
        .then(data => {
          const driverCycleColumnKey = Object.keys(data.data.data[0])
          const driverColumn = driverCycleColumnKey.map((key) => {
            return {
              Header: key,
              accessor: data => replaceBlankWithDash(data[key]),
              id: key,
              minWidth: key === "From" || key === "To" ? 250 : 160,
            }
          })
          this.setState({
            showFourth: true,
            driverColumn,
            driversInCycleData: data.data.data,
            driverCycleCount: data.data.count,
            dataLoaded: true,
            loader: false,
          })
        })
        .catch(error => {
          console.log("error", error.response)
          if (!this.state.startDate) {
            this.setState({
              isOpenSnackbar: true,
              snackbarMessage: "Please select a date range",
            });
          }
          else {
            let message =
              error &&
                error.response &&
                error.response.statusText
                // error.response.data.message
                ? error.response.statusText
                : "Data Not Found!";
            this.setState({
              dataLoaded: false,
              loader: false,
              isOpenSnackbar: true,
              snackbarMessage: message,
            });
          }
          setTimeout(() => {
            this.setState({
              isOpenSnackbar: false,
            });
          }, 1000);
        })
    }
    else if (driversCycleHeader === "Delivery Count") {
      fetchDriverOrder(
        moment(startDate).unix(),
        moment(endDate).unix(),
        cityId,
        service,
        skip,
        limit,
        driverId,
        exportData
      )
        .then(data => {
          const driverCycleColumnKey = Object.keys(data.data.data[0])
          const driverColumn = driverCycleColumnKey.map((key) => {
            return {
              Header: key,
              accessor: data => replaceBlankWithDash(data[key]),
              id: key,
              minWidth: key === "From" || key === "To" ? 250 : 160,
            }
          })
          this.setState({
            showFourth: true,
            driverColumn,
            driversInCycleData: data.data.data,
            driverCycleCount: data.data.count,
            dataLoaded: true,
            loader: false,
          })
        })
        .catch(error => {
          console.log("error", error.response)
          if (!this.state.startDate) {
            this.setState({
              isOpenSnackbar: true,
              snackbarMessage: "Please select a date range",
            });
          } else {
            let message =
              error &&
                error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : "Data Not Found!";
            this.setState({
              dataLoaded: false,
              loader: false,
              isOpenSnackbar: true,
              snackbarMessage: message,
            });
          }
          setTimeout(() => {
            this.setState({
              isOpenSnackbar: false,
            });
          }, 1000);
        })
    }
    else {
      this.setState({
        dataLoaded: false,
        loader: false,
        isOpenSnackbar: true,
        snackbarMessage: "Data Not Found"
      })
    }
    setTimeout(() => {
      this.setState({
        isOpenSnackbar: false,
      });
    }, 1000);
  }

  //method to fetch data
  getData = (countryId, skip, limit) => {
    this.setState({
      loader: true
    })
    fetchDriverDetails(
      countryId,
      skip,
      limit,
    )
      .then(data => {
        this.setState({
          showFirst: true,
          logsData: data.data.data,
          dataLoaded: true,
          loader: false,
        });
      })
      .catch(error => {
        console.log("error", error.response)
        if (!this.state.startDate) {
          this.setState({
            isOpenSnackbar: true,
            snackbarMessage: "Please select a date range",
          });
        } else {
          let message =
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : "Data Not Found!";
          this.setState({
            dataLoaded: false,
            loader: false,
            isOpenSnackbar: true,
            snackbarMessage: message,
          });
        }
        setTimeout(() => {
          this.setState({
            isOpenSnackbar: false,
          });
        }, 1000);
      });
  };

  dropDownSelect = (e) => {
    this.setState({
      storeIdValue: e.target.value,
    });
  };

  handleFilterData = (value, name) => {
    try {
      if (name === "initialCall") {
        getCountries()
          .then((response) => {
            if (response && response.data && response.data.data) {
              this.setState({
                countries: response.data.data,
              });
            }
          })
          .catch((error) => {
            console.log("Countries api error", error);
          });
      }
      if (name === "countries") {
        this.setState({
          country: name,
          countryId: value === null ? "" : value,
        });
      }

      let key =
        name === "countries"
          ? "country"
          : "";
      if (key) {
        const data = this.state[name];
        this.setState({
          [key]: _.result(
            _.find(data, function (obj) {
              return obj['_id'] === value;
            }),
            // "name"
            "countryName"
          ),
        });
      }
    } catch (err) {
      console.log("Something went wrong here", err);
    }
  };

  headerStateHandler = (stateName, stateValue) => {
    if (stateName === "date" && stateValue) {
      this.setState({
        startDate: stateValue.startDate,
        endDate: stateValue.endDate,
      });
    }
  };


  //for getting the export report besed on selected date.
  GeneratePostData = (startDate, endDate, city_id, city_name, service) => {
    var timezone = jstz.determine();
    let yourTimeZone = timezone.name();

    const dataToSend = {
      start_time: startDate,
      end_time: endDate,
      city_id: city_id,
      city_name: city_name,
      service: service,
      timezone: yourTimeZone
    };
    //calling the post api
    GenerateReportData(dataToSend)
      .then((res) => {
        window.open(res.data, "_self") // using for downloading the generated report 
      })

      .catch((e) => {
        console.log(e, "errrrrrrrrrrrrr");
        this.setState({ loader: false });
      });
  };


  generateReport = () => {
    this.setState({
      loader: true,
      skip: 0,
      limit: 10,
    })
    const { startDate, endDate, tableHeader, cityId, cityName, skip, limit } = this.state
    if (tableHeader == "Independent Drivers") {
      fetchIndependentDriver(
        moment(startDate).unix(),
        moment(endDate).unix(),
        cityId,
      )
        .then(data => {
          this.GeneratePostData(
            moment(startDate).unix(),
            moment(endDate).unix(),
            cityId,
            cityName,
            DRIVER_CODES.INDEPENDENT_DRIVER
          )
          this.setState({
            showSec: true,
            secondlogsData: data.data.data,
            dataLoaded: true,
            loader: false,
          })
        })
        .catch(error => {
          console.log("error", error.data)
          if (!this.state.startDate) {
            this.setState({
              isOpenSnackbar: true,
              snackbarMessage: "Please select a date range",
            });
          } else {
            let message =
              error &&
                error.data &&
                error.data.message
                ? error.data.message
                : "Data Not Found!";
            this.setState({
              dataLoaded: false,
              loader: false,
              isOpenSnackbar: true,
              snackbarMessage: message,
            });
          }
          setTimeout(() => {
            this.setState({
              isOpenSnackbar: false,
            });
          }, 1000);
        })
    }

    else if (tableHeader == "Fleet Operators") {
      fetchFleetDriver(
        moment(startDate).unix(),
        moment(endDate).unix(),
        cityId,
      )
        .then(data => {
          this.GeneratePostData(
            moment(startDate).unix(),
            moment(endDate).unix(),
            cityId,
            cityName,
            DRIVER_CODES.FLEET_OPERATORS
          )
          this.setState({
            secondlogsData: data.data.data,
            dataLoaded: true,
            loader: false,
            showSec: true,

          })
        })
        .catch(error => {
          console.log("error", error)
          if (!this.state.startDate) {
            this.setState({
              isOpenSnackbar: true,
              snackbarMessage: "Please select a date range",
            });
          } else {
            let message =
              error &&
                error.data &&
                error.data.message
                ? error.data.message
                : "Data Not Found!";
            this.setState({
              dataLoaded: false,
              loader: false,
              isOpenSnackbar: true,
              snackbarMessage: message,
            });
          }
          setTimeout(() => {
            this.setState({
              isOpenSnackbar: false,
            });
          }, 1000);
        })
    }

    else if (tableHeader == "Stores") {
      fetchStoreDriver(
        moment(startDate).unix(),
        moment(endDate).unix(),
        cityId,
      )
        .then(data => {
          this.GeneratePostData(
            moment(startDate).unix(),
            moment(endDate).unix(),
            cityId,
            cityName,
            DRIVER_CODES.STORES
          )
          this.setState({
            secondlogsData: data.data.data,
            dataLoaded: true,
            loader: false,
            showSec: true,

          })
        })
        .catch(error => {
          console.log("error", error)
          if (!this.state.startDate) {
            this.setState({
              isOpenSnackbar: true,
              snackbarMessage: "Please select a date range",
            });
          } else {
            let message =
              error &&
                error.data &&
                error.data.message
                ? error.data.message
                : "Data Not Found!";
            this.setState({
              dataLoaded: false,
              loader: false,
              isOpenSnackbar: true,
              snackbarMessage: message,
            });
          }
          setTimeout(() => {
            this.setState({
              isOpenSnackbar: false,
            });
          }, 1000);
        })
    }
    else if (tableHeader == "Generated Report") {
      fetchGeneratedReport(
        cityId,
        moment(startDate).unix(),
        moment(endDate).unix(),
        skip,
        limit,
        this.state.selectedSortByService.value

      )
        .then(data => {
          this.setState({
            callGeneratedReport: true,
            secondlogsData: data.data.data,
            generatedReportCount: data.data.count,
            dataLoaded: true,
            loader: false,
            showSec: true,

          })
        })
        .catch(error => {
          console.log("error", error.response)
          if (!this.state.startDate) {
            this.setState({
              isOpenSnackbar: true,
              snackbarMessage: "Please select a date range",
            });
          } else {
            let message =
              error &&
                error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : "Data Not Found!";
            this.setState({
              dataLoaded: false,
              loader: false,
              isOpenSnackbar: true,
              snackbarMessage: message,
            });
          }
          setTimeout(() => {
            this.setState({
              isOpenSnackbar: false,
            });
          }, 1000);
        })
    }
    else {
      this.setState({
        dataLoaded: false,
        loader: false,
        isOpenSnackbar: true,
        snackbarMessage: "Data Not Found"
      })

    }
    setTimeout(() => {
      this.setState({
        isOpenSnackbar: false,
      });
    }, 1000);
  }

  sortSelectHandler = (option) => {
    if (option.value === 1 || option.value === 2 || option.value === 3) {

      this.setState({
        selectedSortByService: option,
      });
    }
  };

  //Exporting the logs table data
  exportReport = () => {
    this.setState({
      loader: true,
      exportData: 1
    })
    setTimeout(() => {
      if (this.state.showThird) {
        this.secPayoutCellHandler(this.state.secPayoutEvent)
      }
      if (this.state.showFourth) {
        this.subDriversCycleHandler(this.state.driversCycleEvent)
      }
      this.setState({
        isExport: !this.state.isExport
      });
    }, 1000);
  }

  showFirstHandler = () => {
    if (this.state.showSec) {
      this.setState({
        showFirst: true,
        secPayoutReportColumns: [],
        secondlogsData: [],
        showSec: false
      })

    }

  }

  showSecHandler = () => {
    if (!this.state.showSec) {
      this.setState({
        exportData: 0,
        showSec: true,
        showThird: false,
        showFourth: false
      })
    }
    if (!this.state.showThird) {
      this.setState({
        showSec: false,
        showThird: true,
        showFourth: false
      })
    }
  }

  noDataFirstHandler = () => {
    this.setState({
      showFirst: true,
      showSec: false,
      showThird: false,
      showFourth: false,
    }
    )
  }

  noDataSecHandler = () => {
    if (this.state.driversCycleHeader === "Ride Count" || this.state.driversCycleHeader === "Delivery Count" && !this.state.showFourth) {
      this.setState({
        showFourth: false,
        showThird: true
      })
    }
    else if (this.state.logsHeader) {
      this.setState({
        showThird: false,
        showSec: true,
        showFourth: false,
      })
    }

  }

  render() {
    const { country, city } = this.state;
    return (
      <div className="overviewWrapper" ref={this.myRef}>
        <div className="head">
          <div className="title">
            <span>Payout Reports</span>
          </div>

          {this.state.showThird || this.state.showFourth ?
            <div className="d-flex text-grey mt-2 justify-content-end" style={{ marginBottom: "", }}>
              <div
                className="mr-3 db_ptr"
                style={{ cursor: "pointer" }}
                onClick={this.exportReport}
              >
                <button className="mx-4 reportModal-Btn globalFontSize" style={{ background: "#dee2e6" }} >Download Report </button>
              </div>
            </div>
            : ""}

          <div className={this.state.showThird ? "d-flex justify-content-end align-items-center" : "d-flex justify-content-between align-items-center"}>
            <div className="mt-2">
              <Headers
                generateReport={this.generateReport}

                showDateFilter={this.state.showSec}
                showExport={this.state.showThird}
                showCountryFilter={this.state.showFirst}

                tableHeader={this.state.tableHeader}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                headerStateHandler={this.headerStateHandler}
                countries={this.state.countries}
                selectHandle={this.handleFilterData}
                country={country}

                // service drop down 
                sortByService={this.state.sortByService}
                sortSelectHandler={this.sortSelectHandler}
                selectedSortByService={this.state.selectedSortByService}
              />
            </div>
          </div>
        </div>

        {
          this.state.showFirst ?
            <>
              <ReactTable
                showCase={this.state}
                lable="Payout Report"
                columns={this.driverPayoutReportColumns.filter((item) => {
                  if (this.state.projectType == 0) {
                    return true
                  }
                  if (this.state.projectType == 1) {
                    // return !item.Header.includes("Fleet Operators")
                    return !item.Header.includes("Independent Drivers")
                  }
                  if (this.state.projectType == 2) {
                    return !item.Header.includes("Fleet Operators")
                  }
                  if (this.state.projectType == 3) {
                    return !item.Header.includes("Stores")
                  }
                })}
                // columns={this.driverPayoutReportColumns}
                data={this.state.logsData}
              // noDataText="No review logs"
              />
              <div className="paginationWrapper">
                {/* <PaginationJs
            changeActivePage={this.changeActivePage}
            activePage={this.state.page}
            totalData={this.state.totalData}
            dataToShowHandler={this.dataToShowHandler}
            dataToShow={this.state.daDriver taToShow}         
          /> */}
              </div>
            </>

            : this.state.showSec && this.state.tableHeader == "Generated Report" ?
              <>
                <ReactTable
                  loader={this.state.loader}
                  showCase={this.state}
                  lable={this.state.tableHeader}
                  columns={this.generatedReportColumns}
                  data={this.state.secondlogsData}
                  showFirstHandler={this.showFirstHandler}
                  showSecHandler={this.showSecHandler}
                  noDataText="No data found between selected date range"

                />
                <div className="paginationWrapper">
                  <PaginationJs
                    changeActivePage={this.changeActivePage}
                    activePage={this.state.page}
                    totalData={this.state.generatedReportCount}
                    dataToShowHandler={this.dataToShowHandler}
                    dataToShow={this.state.dataToShow}
                  />
                </div>
              </>

              : this.state.showSec ?
                <>
                  <ReactTable
                    loader={this.state.loader}
                    showCase={this.state}
                    tableFormater="tableFormater sec-table-header"
                    lable={this.state.tableHeader}
                    columns={this.secPayoutReportColumns}
                    data={this.state.secondlogsData}
                    noDataText="Please select start date and end date to generate report"
                    showFirstHandler={this.showFirstHandler}
                    showSecHandler={this.showSecHandler}
                  />
                  <div className="paginationWrapper">
                    {/* <PaginationJs
            changeActivePage={this.changeActivePage}
            activePage={this.state.page}
            totalData={this.state.totalData}
            dataToShowHandler={this.dataToShowHandler}
            dataToShow={this.state.dataToShow}         
          /> */}
                  </div>
                </>

                // : this.state.showThird && this.state.dataLoaded ?
                : this.state.showThird ?
                  <>
                    <ReactTable
                      showCase={this.state}
                      lable={this.state.logsHeader}
                      columns={this.state.logsColumn}
                      data={this.state.thirdlogsData}
                      // noDataText="No review logs"
                      showFirstHandler={this.showFirstHandler}
                      showSecHandler={this.showSecHandler}
                    />

                    <div className="paginationWrapper">
                      <PaginationJs
                        changeActivePage={this.changeActivePage}
                        activePage={this.state.page}
                        totalData={this.state.totalData}
                        dataToShowHandler={this.dataToShowHandler}
                        dataToShow={this.state.dataToShow}
                      />
                    </div>
                  </>


                  : this.state.showFourth ?
                    <>
                      <ReactTable
                        showCase={this.state}
                        lable={this.state.driversCycleHeader}
                        columns={this.state.driverColumn}
                        data={this.state.driversInCycleData}
                        // noDataText="No review logs"
                        showSecHandler={this.showSecHandler}
                        showFirstHandler={this.showFirstHandler}
                      />

                      <div className="paginationWrapper">
                        <PaginationJs
                          changeActivePage={this.changeActivePage}
                          activePage={this.state.page}
                          totalData={this.state.driverCycleCount}
                          dataToShowHandler={this.dataToShowHandler}
                          dataToShow={this.state.dataToShow}
                        />
                      </div>
                    </>


                    : this.state.loader ?
                      <div className="clip-loader ml-2 card align-items-center justify-content-center">
                        <ClipLoader color="blue" size={50} />
                      </div>
                      : !this.state.dataLoaded ?

                        <div className="noData ml-2 card">
                          <div className="d-flex ml-auto align-items-center pt-3 pr-3 breadcustom">
                            <Breadcrumbs
                              showCase={this.state}
                              showFirstHandler={this.noDataFirstHandler}
                              showSecHandler={this.noDataSecHandler}
                            />
                          </div>
                          <div className="d-flex align-items-center justify-content-center" style={{ height: "600px" }}>
                            <h3 style={{ color: "rgb(42, 63, 84)", fontSize: "24px", fontWeight: '600' }}>Data Not Found</h3>
                          </div>
                        </div>


                        : <></>

        }

        {/* Export Modal will only open when user click on export button then only this modal will to ask for export and cancel */}
        <Modal
          isOpen={this.state.isExport}
          toggle={() => this.setState({ isExport: !this.state.isExport })}
          width={"35%"}
        >
          <div className="col-12 px-0">
            <div className="py-3 reportModal-header pl-3 border-bottom">
              Export your Table Data
            </div>
            <div className="py-3 reportModal-subText pl-3 border-bottom">
              Table will be exported as a CSV (comma separated values).
            </div>
            <div className="py-3 col-12">
              <div className="d-flex justify-content-end">
                <Pbutton
                  onClick={() =>
                    this.setState({ isExport: !this.state.isExport })
                  }
                  className="reportModal-cancelBtn"
                >
                  Cancel
                </Pbutton>
                <CSVLink
                  onClick={() =>
                    this.setState({ isExport: !this.state.isExport })
                  }
                  data={this.state.showThird ? this.state.thirdlogsData : this.state.showFourth ? this.state.driversInCycleData : ""}
                  // filename={"my-file.csv"}                    
                  filename={`${this.state.showSec ? this.state.tableHeader : this.state.showThird ? this.state.logsHeader : this.state.showFourth ? this.state.driversCycleHeader : ''}.csv`}
                  className="reportModal-exportBtn"
                  target="_self"
                >
                  Export
                </CSVLink>
              </div>
            </div>
          </div>
        </Modal>

        <Snackbar
          open={this.state.isOpenSnackbar}
          message={this.state.snackbarMessage}
        />

      {/* Using this dialog box for Drivers To pay and pay now button" */}
        <CustDialog
          isOpen={this.state.isOpen}
          handleClose={this.handleDialogClose}
          title="Please confirm to make the payment"
          cancelBtnTxt="cancel"
          submitBtnTxt="ok"
          submitBtnFn={this.openConfirmDialog}
          cancelBtnFn={this.handleDialogClose}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setIndex: (index) => dispatch(setIndex(index)),
  };
};

export default connect(null, mapDispatchToProps)(DriverPayoutOverview);
