import LOGO from "../static/images/app_images/appscrip.png";
import WEBP_1 from "../static/images/dummy/1.webp";
import AppLogo from "../static/images/app_logo/main_logo.png";
import LogOut from "../static/images/assets/logout.png";
import BackArrow from "../static/images/assets/back.png";
import Message from "../static/images/assets/message.png"

export const MESSAGE = Message;
export const APP_NAME = "Appscrip";
export const MAIN_LOGO = LOGO;
export const WEBP_DUMMY_1 = WEBP_1;

// OG DATA VARS
export const OG_IMAGE =
  "https://www.appscrip.com/wp-content/uploads/2019/03/Group-4612.png";

//app logo
export const APP_LOGO = AppLogo;
export const LOGOUT = LogOut;

export const PRIMARY = "#3a3abf";
export const LIGHT_PRIMARY = "#8181ec";
export const SHADOW =
  "box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px #8181ec !important;";

export const BACK_ARROW = BackArrow


// enum methods
export const DRIVER_CODES =  { 
                        "INDEPENDENT_DRIVER": 1,
                        "FLEET_OPERATORS":2,
                        "STORES":3
                        } 

export const DEFAULT_COUNTRY = {
  COUNTRY : '5df7b7218798dc2c1114e6c0'
}

export const PROJECT_TYPE_SUPPORT = {
  ALL : 0,
  INDEPENDENT_DRIVER :1,
  FLEET_OPERATORS: 2,
  STORES: 3
}

export const METHOD = {
  'Drivers To Collect From': 1,
  'Drivers To pay' :2
}


export const USER_TYPE_SUPPORT = {DRIVER:2, STORE : 1}
export const TXN_TYPE_SUPPORT = {PAY:2, COLLECT : 1}


