//library import
import React, { useState } from "react";
import ReactTable from "react-table";
import DescriptionIcon from "@material-ui/icons/Description";
import moment from "moment";
import Breadcrumbs from '../breadcrumbs/Breadcrumbs'
import "antd/dist/antd.css";

import SearchIcon from '@material-ui/icons/Search';

//css import
import "./reactTable.scss";
import "./react-table.css";
import ClipLoader from "react-spinners/ClipLoader";

class reactTable extends React.Component {
  intDate = {
    startdate: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
  };

  state = {
    filtered: [],
    addModalShow: false,
    header : [
      "Total Drivers In Cycle", 
      "Drivers To Collect From",
      "Drivers To pay"
    ]
  };

  handlerDialog = (flag) => {
    this.setState({ open: flag });
  };

  render() {
    const {lable} = this.props

    return (
      <React.Fragment>
        <div className={`react-table ${this.props.tableFormater? this.props.tableFormater : ""}`} style={{top:"14rem"}}>
          <div className="table-lable-div">
            <div className="table-icon">
              <DescriptionIcon></DescriptionIcon>
            </div>
            <div className="table-lable" style={{padding:"10px"}}>{this.props.lable || ""}</div>


            { this.state.header.includes(lable) ?
            <div className={`position-relative d-flex align-items-center mt-3 ml-auto`}>
              <SearchIcon className="search-icon"></SearchIcon>
              <input className="search-box"  placeholder="Search....">
              </input>
            </div> : ""}



            <div className={`d-flex ${this.state.header.includes(lable) ?  "ml-3": "ml-auto"} align-items-center pt-3 pr-3 breadcustom`}>
              <Breadcrumbs
                  showCase={this.props.showCase}  
                  showFirstHandler={this.props.showFirstHandler}
                  showSecHandler = {this.props.showSecHandler}
                  />
            </div>
          </div>
          {this.props.loader ?  
            <div style={{position:"absolute", top:"50%", left:"48%", zIndex:"9999"}}>
              <ClipLoader color="blue" size={50} />
              </div> :
              <ReactTable
              resizable={false}
              data={this.props.data}
              pageSize={this.props.data && this.props.data.length}
              showPagination={false}
              className="-striped -highlight"
              columns={this.props.columns}
              minRows={this.props.data.length}
              noDataText={this.props.noDataText || "No rows found"}
            />
            }

          {/* <ReactTable
            resizable={false}
            data={this.props.data}
            pageSize={this.props.data && this.props.data.length}
            showPagination={false}
            className="-striped -highlight"
            columns={this.props.columns}
            minRows={this.props.data.length}
            noDataText={this.props.noDataText || "No rows found"}
          /> */}
        </div>
      </React.Fragment>
    );
  }
}
export default reactTable;
