// export const api = "https://dev-auto-api.hugoapp.com";
// export const api = "https://api.shoppd.net";
// export const loginApi = "https://api.shoppd.net/v1/manager/logIn";
// export const regionApi = "https://api.shoppd.net/v1/ipLocation";
// export const resetPasswordApi = "https://api.shoppd.net/v1/manager/forgotPassword";

// docker for uncomment below line
export const api = window._env_.API_URL; 
export const loginApi = `${window._env_.API_URL}/v1/manager/logIn`;
export const regionApi = `${window._env_.API_URL}/v1/ipLocation`;
export const resetPasswordApi = `${window._env_.API_URL}/v1/manager/forgotPassword`;



// If you want to use some image in your charts
// export const IMAGES = {
//   Suvon: require("../static/images/car_icon/suv_car_icon_on.png"),
//   Suv: require("../static/images/car_icon/suv_car_icon_off.png"),
// };
