import React, { Component } from "react";
import Pagination from "react-js-pagination";

import "./paginationJs.scss";

class PaginationJs extends Component {
  handlePageChange = (pageNumber) => {
    this.props.changeActivePage(pageNumber);
  };

  changeSize = (newVal) => {
    this.props.dataToShowHandler(newVal);
  };

  paginationSelector = (totalData) => {
    return (
      <>
        <>
          {totalData > 10 ? (
            <>
              <div
                className={`size ${
                  this.props.dataToShow === 10 ? "selected" : ""
                }`}
                onClick={() => this.changeSize(10)}
              >
                10
              </div>
              <div className="divider">|</div>
              <div
                className={`size ${
                  this.props.dataToShow === 15 ? "selected" : ""
                }`}
                onClick={() => this.changeSize(15)}
              >
                15
              </div>
            </>
          ) : null}
        </>
        <>
          {totalData > 15 ? (
            <>
              <div className="divider">|</div>
              <div
                className={`size ${
                  this.props.dataToShow === 20 ? "selected" : ""
                }`}
                onClick={() => this.changeSize(20)}
              >
                20
              </div>
            </>
          ) : null}
        </>
        <>
          {totalData > 20 ? (
            <>
              <div className="divider">|</div>
              <div
                className={`size ${
                  this.props.dataToShow === 25 ? "selected" : ""
                }`}
                onClick={() => this.changeSize(25)}
              >
                25
              </div>
            </>
          ) : null}
        </>
        {/* <>
          {totalData > 25 ? (
            <>
              <div className="divider">|</div>
              <div
                className={`size ${
                  this.props.dataToShow === 25 ? "selected" : ""
                }`}
                onClick={() => this.changeSize(25)}
              >
                25
              </div>
            </>
          ) : null}
        </> */}
      </>
    );
  };

  render() {
    return this.props.totalData > 10 ? (
      <div>
        <div className="limitWrapper">
          {this.paginationSelector(this.props.totalData)}
        </div>
        <Pagination
          activePage={this.props.activePage}
          itemsCountPerPage={this.props.dataToShow}
          totalItemsCount={this.props.totalData}
          pageRangeDisplayed={5}
          onChange={this.handlePageChange}
        />
      </div>
    ) : null;
  }
}

export default PaginationJs;
