import { getForReport } from "../lib/request";

export const getCountries = () => {
  let URL = "/countries";
  // let URL = "/python-spark/countries";
  return getForReport(URL);
};

export const getCities = (country_id) => {
  return getForReport(`/ride/cities?country_id=${country_id}`);
};

