import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import GrainIcon from '@material-ui/icons/Grain';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import './index.css'


const useStyles = makeStyles((theme) => ({
  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));


export default function IconBreadcrumbs(props) {

  const classes = useStyles();

  return (
    <Breadcrumbs aria-label="breadcrumb"> 
    {props.showCase.showSec && !props.showCase.showFirst ? 
    <>

      <Link color="inherit" onClick={props.showFirstHandler} className={classes.link}>
        <HomeIcon className={classes.icon} />
        Payout Report
      </Link>

      <Typography color="textPrimary" className={classes.link}>
        <WhatshotIcon className={classes.icon} />
          {props.showCase.tableHeader}
      </Typography>

      </>
      : props.showCase.showThird || props.showCase.logsHeader && !props.showCase.driversCycleHeader&& !props.showCase.showFourth && !props.showCase.showFirst? 
      <>  
      <Link color="inherit" onClick={props.showSecHandler} className={classes.link}>
        <WhatshotIcon className={classes.icon} />
          {props.showCase.tableHeader}
      </Link>

      <Typography color="textPrimary" className={classes.link}>
        <GrainIcon className={classes.icon} />
        {props.showCase.logsHeader}
      </Typography>

      </> : props.showCase.showFourth || props.showCase.driversCycleHeader && !props.showCase.showThird && !props.showCase.showFirst ?

        <> 
        <Link color="inherit" onClick={props.showSecHandler} className={classes.link} >
          <WhatshotIcon className={classes.icon} />
            {props.showCase.logsHeader}
        </Link>

        <Typography color="textPrimary" className={classes.link}>
          <GrainIcon className={classes.icon} />
          {props.showCase.driversCycleHeader}
        </Typography>

        </> : ""

      }

    </Breadcrumbs>
  );  
}
